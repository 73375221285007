import React from "react";
import { Text, Button, Container } from "@atoms";

const HighlightedText = ({ heading, descriptor, button }) => {
  return (
    <Container variant="sm" className="flex flex-col items-center gap-10">
      {heading && (
        <Text richText className="bold-teal text-center">
          {heading}
        </Text>
      )}
      {descriptor && (
        <Text variant="xl" className="text-center">
          {descriptor}
        </Text>
      )}
      {button.url && (
        <div>
          <Button to={button.url}>{button.text}</Button>
        </div>
      )}
    </Container>
  );
};

export default HighlightedText;
